import React, {memo} from 'react'
import {animated, useSpring} from 'react-spring'
import {getString} from "../utils/language"
import {animatedSection} from "../utils/animation"
import {iconsColor, textColor, themeColor, white} from "../utils/colors"
import styled from "@emotion/styled"
import {
    FaBootstrap,
    FaReact,
    FaGitAlt,
    FaJava,
    FaPhp,
    FaLaravel,
    FaBitbucket,
    FaJenkins,
    FaHubspot
} from "react-icons/fa"
import {StyledFlexDiv} from "./common"
import {IoLogoJavascript} from "react-icons/io"
import {DiJqueryLogo, DiScala, DiRedis, DiMongodb, DiPostgresql, DiSpark, DiCss3} from "react-icons/di"
import {GrMysql} from "react-icons/gr"
import {AiFillGitlab} from "react-icons/ai"
import apiGateway from "../img/aws-api-gateway.svg"
import cloudWatch from "../img/aws-cloudwatch.svg"
import cognito from "../img/aws-cognito.svg"
import dynamo from "../img/aws-dynamodb.svg"
import kinesis from "../img/aws-kinesis.svg"
import lambda from "../img/aws-lambda.svg"
import s3 from "../img/aws-s3.svg"
import ses from "../img/aws-ses.svg"
import elasticsearch from "../img/elasticsearch.svg"
import eslint from "../img/eslint.svg"
import firebase from "../img/firebase.svg"
import hibernate from "../img/hibernate.svg"
import intellij from "../img/intellij-idea.svg"
import jest from "../img/jest.svg"
import jira from "../img/jira.svg"
import junit from "../img/junit.png"
import kafka from "../img/kafka-icon.svg"
import kibana from "../img/kibana.svg"
import oracle from "../img/oracle.svg"
import postman from "../img/postman.svg"
import ramda from "../img/ramda.svg"
import redux from "../img/redux.svg"
import sparkPost from "../img/sparkpost.svg"
import spring from "../img/spring.svg"
import swagger from "../img/swagger.svg"
import testcafe from "../img/testcafe.svg"
import tomcat from "../img/tomcat.svg"
import twilio from "../img/twilio.svg"
import typescript from "../img/typescript-icon.svg"
import kettle from "../img/kettle.png"
import teamcity from "../img/teamcity.png"
import withSection from './withSection'

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${({isDesktopOrLaptop}) => isDesktopOrLaptop ? "210px" : "80px"}, 1fr));
    justify-items: center;
`

const iconStyle = {backgroundColor: white, borderRadius: "10%"}

const Img = src => <img src={src} alt="logo" style={{filter: "grayscale(100%)", ...iconStyle}} width="48px"
                        height="48px"/>

const Stack = memo(({isDesktopOrLaptop}) => {
    const props = useSpring(animatedSection)
    const StyledSpan = styled.span`
        color: ${textColor()};
        text-align: center;
        font-size: ${isDesktopOrLaptop ? "16px" : "14px"};
    `
    return <animated.div style={props}>
        <h2 style={{textAlign: "center", ...themeColor(), ...(!isDesktopOrLaptop && {margin: "10px"})}}>Tech Stack</h2>
        <h3 style={{
            textAlign: "center",
            marginBottom: "5px", ...themeColor(), ...(!isDesktopOrLaptop && {margin: "10px"})
        }}>
            {getString("stack.languages")}
        </h3>
        <div>
            <StyledContainer {...{isDesktopOrLaptop}}>
                <StyledFlexDiv>
                    <IoLogoJavascript color={iconsColor(true)} size="3em" style={iconStyle}/>
                    <StyledSpan>JavaScript</StyledSpan>
                </StyledFlexDiv>
                <StyledFlexDiv>
                    <FaJava color={iconsColor(true)} size="3em" style={iconStyle}/>
                    <StyledSpan>Java</StyledSpan>
                </StyledFlexDiv>
                <StyledFlexDiv>
                    <DiScala color={iconsColor(true)} size="3em" style={iconStyle}/>
                    <StyledSpan>Scala</StyledSpan>
                </StyledFlexDiv>
                <StyledFlexDiv>
                    {Img(typescript)}
                    <StyledSpan>TypeScript</StyledSpan>
                </StyledFlexDiv>
                <StyledFlexDiv>
                    <FaPhp color={iconsColor(true)} size="3em" style={iconStyle}/>
                    <StyledSpan>PHP</StyledSpan>
                </StyledFlexDiv>
                <StyledFlexDiv>
                    <DiCss3 color={iconsColor(true)} size="3em" style={iconStyle}/>
                    <StyledSpan>CSS</StyledSpan>
                </StyledFlexDiv>
            </StyledContainer>
        </div>
        <h3 style={{
            textAlign: "center",
            marginBottom: "5px", ...themeColor(), ...(!isDesktopOrLaptop && {margin: "10px"})
        }}>
            {getString("stack.libraries")}
        </h3>
        <StyledContainer {...{isDesktopOrLaptop}}>
            <StyledFlexDiv>
                <FaReact color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>React</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <FaBootstrap color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>Bootstrap</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(redux)}
                <StyledSpan>Redux</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(spring)}
                <StyledSpan>Spring</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <FaLaravel color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>Laravel</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(jest)}
                <StyledSpan>Jest</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(junit)}
                <StyledSpan>JUnit</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(ramda)}
                <StyledSpan>Ramda</StyledSpan>
            </StyledFlexDiv>
        </StyledContainer>
        <h3 style={{
            textAlign: "center",
            marginBottom: "5px", ...themeColor(), ...(!isDesktopOrLaptop && {margin: "10px"})
        }}>
            {getString("stack.databases")}
        </h3>
        <StyledContainer {...{isDesktopOrLaptop}}>
            <StyledFlexDiv>
                {Img(firebase)}
                <StyledSpan>Firebase</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(elasticsearch)}
                <StyledSpan>ElasticSearch</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <GrMysql color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>MySQL</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <DiPostgresql color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>PostgreSQL</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <DiMongodb color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>MongoDB</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <DiRedis color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>Redis</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(oracle)}
                <StyledSpan>Oracle</StyledSpan>
            </StyledFlexDiv>
        </StyledContainer>
        <h3 style={{
            textAlign: "center",
            marginBottom: "5px", ...themeColor(), ...(!isDesktopOrLaptop && {margin: "10px"})
        }}>
            AWS
        </h3>
        <StyledContainer {...{isDesktopOrLaptop}}>
            <StyledFlexDiv>
                {Img(lambda)}
                <StyledSpan>Lambda</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(apiGateway)}
                <StyledSpan>ApiGateway</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(cloudWatch)}
                <StyledSpan>CloudWatch</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(cognito)}
                <StyledSpan>Cognito</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(dynamo)}
                <StyledSpan>DynamoDB</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(s3)}
                <StyledSpan>S3</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(ses)}
                <StyledSpan>SES</StyledSpan>
            </StyledFlexDiv>
        </StyledContainer>
        <h3 style={{
            textAlign: "center",
            marginBottom: "5px", ...themeColor(), ...(!isDesktopOrLaptop && {margin: "10px"})
        }}>
            {getString("stack.other")}
        </h3>
        <StyledContainer {...{isDesktopOrLaptop}}>
            <StyledFlexDiv>
                <FaGitAlt color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>Git</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <FaBitbucket color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>Bitbucket</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <AiFillGitlab color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>GitLab</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <FaJenkins color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>Jenkins</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(jira)}
                <StyledSpan>Jira</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(eslint)}
                <StyledSpan>ESLint</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(kettle)}
                <StyledSpan>Kettle PDI</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(teamcity)}
                <StyledSpan>TeamCity</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(intellij)}
                <StyledSpan>IntelliJ IDEA</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(hibernate)}
                <StyledSpan>Hibernate</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(sparkPost)}
                <StyledSpan>SparkPost</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(swagger)}
                <StyledSpan>Swagger</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(testcafe)}
                <StyledSpan>Test Café</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(tomcat)}
                <StyledSpan>Apache Tomcat</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(twilio)}
                <StyledSpan>Twilio</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(kafka)}
                <StyledSpan>Kafka</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(kibana)}
                <StyledSpan>Kibana</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(postman)}
                <StyledSpan>Postman</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <DiSpark color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>Spark</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <FaHubspot color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>HubSpot</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                <DiJqueryLogo color={iconsColor(true)} size="3em" style={iconStyle}/>
                <StyledSpan>JQuery</StyledSpan>
            </StyledFlexDiv>
            <StyledFlexDiv>
                {Img(kinesis)}
                <StyledSpan>AWS Kinesis</StyledSpan>
            </StyledFlexDiv>
        </StyledContainer>
    </animated.div>
})

export default withSection(Stack)
