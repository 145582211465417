import React from 'react'

const withSection = (Component) => ({isDesktopOrLaptop, language, theme}) => {
  return (
    <div
        style={{
            padding: `${isDesktopOrLaptop ? "75px 100px 0px" : "15px 20px 15px"}`,
        }}>
      <Component {...{isDesktopOrLaptop, language, theme}}/>
    </div>
  );
};

export default withSection