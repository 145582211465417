import React, {memo} from 'react'
import {animated, useSpring} from 'react-spring'
import {animatedBasic, animatedSection} from "../utils/animation"
import styled from "@emotion/styled"
import {getString} from "../utils/language"
import {textColor} from "../utils/colors"
import withSection from './withSection'

const StyledSection = styled.div`
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
`

const Education = memo(({isDesktopOrLaptop}) => {
    const props = useSpring(animatedSection)
    const propsEducationDetail = useSpring({...animatedBasic, delay: 300})
    const propsCoursesTitle = useSpring({...animatedBasic, delay: 600})
    const propsCoursesDetail = useSpring({...animatedBasic, delay: 900})
    return <animated.div style={{...props, color: textColor()}}>
        <h2 style={{textAlign: "center"}}>{getString("education.title")}</h2>
        <StyledSection>
            <animated.div style={{...propsEducationDetail, display: "flex", flexDirection: "column"}}>
                <b>{getString("education.degree")}</b>
                <span>{getString("education.university")}</span>
                <small>{getString("education.city")}</small>
            </animated.div>
        </StyledSection>
        <animated.div style={propsCoursesTitle}>
            <h2 style={{textAlign: "center", ...(!isDesktopOrLaptop && {margin: "10px"})}}>
                {getString("education.titleCourses")}
            </h2>
        </animated.div>
        <animated.div style={propsCoursesDetail}>
            {
                getString("education.certifications").map((item, index) => {
                    return <StyledSection key={index}>
                        <b>{item.name}</b>
                        <span>{item.issuer}</span>
                        <a href={item.url}
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{color: textColor()}}
                        >
                            {getString("education.seeCertificate")}
                        </a>
                    </StyledSection>
                })
            }
        </animated.div>
    </animated.div>
})

export default withSection(Education)
