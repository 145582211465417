export const SET_THEME = {
    name: 'SET_THEME',
    action: theme => ({
        theme,
        type: SET_THEME.name
    })
}

export const SET_LANGUAGE = {
    name: 'SET_LANGUAGE',
    action: language => ({
        language,
        type: SET_LANGUAGE.name
    })
}
