import React, {memo} from 'react'
import {animated, useSpring} from "react-spring"
import {animatedSection} from "../utils/animation"
import {textColor} from "../utils/colors"
import {getString} from "../utils/language"
import withSection from './withSection'

const Blog = memo(({isDesktopOrLaptop}) => {
    const props = useSpring(animatedSection)
    return <animated.div style={{...props, color: textColor()}}>
        <h2 style={{textAlign: "center", ...(!isDesktopOrLaptop && {margin: "10px"})}}>{getString("comingSoon")}</h2>
    </animated.div>
})

export default withSection(Blog)
