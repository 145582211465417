import {defaultState} from "./defaultState"

const ui = (state = defaultState.ui, action) => {
    switch (action.type) {
        case 'SET_THEME':
            return {...state, theme: action.theme}
        case 'SET_LANGUAGE':
            return {...state, language: action.language}
        default:
            return state
    }
}

export default ui
