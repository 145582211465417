import React, {memo} from 'react'
import {animated, useSpring} from 'react-spring'
import {animatedBasic, animatedSection} from "../utils/animation"
import styled from "@emotion/styled"
import {iconsColor, textColor} from "../utils/colors"
import {FaCode, FaGamepad, FaHeadphones} from "react-icons/fa"
import {IoIosFootball, IoMdBicycle} from "react-icons/io"
import {getString} from "../utils/language"
import picture from "../img/picture.jpg"
import withSection from './withSection'

const StyledSpan = styled.span`
    text-align: justify;
    margin-top: 15px;
    font-size: ${({isDesktopOrLaptop}) => isDesktopOrLaptop ? "20px" : "15px"}
`

const About = memo(({isDesktopOrLaptop}) => {
    const props = useSpring(animatedSection)
    const animatedContent = useSpring({...animatedBasic, delay: 300})
    const animatedIcons = useSpring({...animatedBasic, delay: 600})
    const iconsSize = isDesktopOrLaptop ? "3em" : "2em"
    const themeColor = {
        color: textColor()
    }

    return <animated.div style={props}>
        <h2 style={{textAlign: "center", ...themeColor, ...(!isDesktopOrLaptop && {margin: "10px"})}}>
            {getString("about.title")}
        </h2>
        <img src={picture}
                                    style={{
                                        borderRadius: "50%",
                                        filter: "grayscale(100%)",
                                        userSelect: "none",
                                        alignSelf: "center",
                                    }}
                                    alt="david"
                                    height="100"
                                    width="100"/>
        <animated.div style={{...animatedContent, ...themeColor, display: "flex", flexDirection: "column"}}>
            {getString("about.paragraphs").map((item, index) =>
                <StyledSpan key={index} {...{isDesktopOrLaptop}}>
                    {item}
                </StyledSpan>)}
        </animated.div>
        <animated.div
            style={{
                ...animatedIcons,
                paddingTop: isDesktopOrLaptop ? "50px" : "20px",
                textAlign: "center"
            }}
        >
            <FaCode color={iconsColor()} size={iconsSize} style={{paddingRight: "15px"}}/>
            <FaGamepad color={iconsColor()} size={iconsSize} style={{paddingRight: "15px"}}/>
            <IoIosFootball color={iconsColor()} size={iconsSize} style={{paddingRight: "15px"}}/>
            <FaHeadphones color={iconsColor()} size={iconsSize} style={{paddingRight: "15px"}}/>
            <IoMdBicycle color={iconsColor()} size={iconsSize}/>
        </animated.div>
    </animated.div>
})

export default withSection(About)
