import React, {useEffect, useState} from 'react'
import {connect} from "react-redux"
import SideBarContent from "./components/SideBarContent"
import About from "./components/About"
import {always, cond, equals, T} from "ramda"
import Sidebar from "react-sidebar"
import {backgroundColor, iconsColor, lightGray, sideBarBackgroundColor, textColor} from "./utils/colors"
import {useMediaQuery} from 'react-responsive'
import styled from "@emotion/styled"
import {GoThreeBars} from "react-icons/go"
import {withRouter} from "react-router-dom"
import Experience from "./components/Experience"
import Education from "./components/Education"
import Blog from "./components/Blog"
import Stack from "./components/Stack"

const StyledHeader = styled.div`
    height: 30px;
    width: 100%;
    background-color: ${lightGray};
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 2px 10px 0px;
    color: ${({color}) => color};
    background-color: ${({backgroundColor}) => backgroundColor};
`

const StyledTitle = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

// const Education = React.lazy(() => import("./components/Education"))
// const Stack = React.lazy(() => import("./components/Stack"))
// const Blog = React.lazy(() => import("./components/Blog"))
// const Experience = React.lazy(() => import("./components/Experience"))

// function LazyContainer(Component) {
//     return (
//         <div>
//             <Suspense fallback={<></>}>
//                 {Component}
//             </Suspense>
//         </div>
//     )
// }

function App({location, history, theme, language}) {
    const [section, setSection] = useState("about")
    const [openedSideBar, setOpenedSideBar] = useState(false)
    const isDesktopOrLaptop = useMediaQuery({minDeviceWidth: 1224})

    const onChangeSection = section => {
        history.push(section)
        setOpenedSideBar(false)
        setSection(section)
    }

    useEffect(() => {
        const path = location.pathname.split('/')[1]
        path && setSection(path)
    }, [location.pathname])

    const props = {isDesktopOrLaptop, theme, language}

    const renderSection = cond([
        [equals("about"), always(<About {...props}/>)],
        // [equals("education"), always(LazyContainer(<Education {...{isDesktopOrLaptop}}/>))],
        [equals("education"), always(<Education {...props}/>)],
        [equals("experience"), always(<Experience {...props}/>)], //TODO lagging while loading with LazyContainer
        [equals("stack"), always(<Stack {...props}/>)],
        [equals("blog"), always(<Blog {...props}/>)],
        [T, always(<About {...props}/>)]
    ])

    return (
        <>
            <Sidebar
                sidebar={<SideBarContent {...{onChangeSection, section, isDesktopOrLaptop}}/>}
                open={isDesktopOrLaptop || openedSideBar}
                docked={isDesktopOrLaptop}
                styles={{sidebar: {background: sideBarBackgroundColor()}, content: {background: backgroundColor()}}}
                touchHandleWidth={0}
                onSetOpen={() => setOpenedSideBar(false)}
            >
                <>
                    {!isDesktopOrLaptop &&
                    <StyledHeader {...{color: textColor(), backgroundColor: sideBarBackgroundColor()}}>
                        <GoThreeBars
                            color={iconsColor()}
                            size="1.5rem"
                            style={{paddingLeft: "10px"}}
                            onClick={() => setOpenedSideBar(true)}
                        />
                        <StyledTitle>
                            <b>David Jara - Sofware Engineer</b>
                        </StyledTitle>
                    </StyledHeader>}

                    {renderSection(section)}
                </>
            </Sidebar>
        </>
    );
}

const mapStateToProps = ({ui: {theme, language}}) => ({theme, language})

export default withRouter(connect(mapStateToProps)(App))
