import React, {memo} from 'react'
import {animated, useSpring} from 'react-spring'
import {animatedBasic, animatedSection} from "../utils/animation"
import {ActivityTimeline, TimelineMarker} from 'react-rainbow-components'
import {FaSuitcase, FaFileAlt} from "react-icons/fa"
import {iconsColor, textColor} from "../utils/colors"
import styled from "@emotion/styled"
import {getString} from "../utils/language"
import withSection from './withSection'

const Experience = memo(({isDesktopOrLaptop}) => {
    const StyledSpan = styled.span`
        color: ${textColor()};
        font-size: ${isDesktopOrLaptop ? "unset" : "small"}
    `
    const props = useSpring(animatedSection)
    let delay = 300
    return <animated.div style={{...props, color: textColor()}}>
        <h2 style={{textAlign: "center", ...(!isDesktopOrLaptop && {margin: "10px"})}}>
            {getString("experience.title")}
        </h2>
        <ActivityTimeline>
            {getString("experience.jobs").map(item => {
                const animatedItem = useSpring({
                    ...animatedBasic,
                    delay
                })
                delay = delay + 300
                return <animated.div style={{...animatedItem, color: textColor()}} key={item.title}>
                    <TimelineMarker
                        label={<StyledSpan><b>{item.title}</b></StyledSpan>}
                        icon={<FaSuitcase color={iconsColor()}/>}
                        datetime={<StyledSpan>{item.time}</StyledSpan>}
                        description={item.description.map((line, index) =>
                            <span key={index}><StyledSpan>{line}</StyledSpan><br/></span>)}
                    />
                </animated.div>
            })}
        </ActivityTimeline>
        <animated.div style={useSpring({...animatedBasic, delay: delay + 300})}>
            <h2 style={{textAlign: "center"}}>{getString("experience.independent.head")}</h2>
        </animated.div>
        <ActivityTimeline>
            {getString("experience.independent.projects").map(item => {
                return <animated.div style={useSpring({...animatedBasic, delay: delay + 300})} key={item.title}>
                    <TimelineMarker
                        label={<StyledSpan><b>{item.title}</b></StyledSpan>}
                        icon={<FaFileAlt color={iconsColor()}/>}
                        datetime={<StyledSpan>{item.time}</StyledSpan>}
                        description={
                            <StyledSpan>
                                <span>{item.description}</span>
                                {item.url && <>
                                    <br/>
                                    <a href={item.ref}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       style={{color: textColor()}}
                                    >
                                        {item.url}
                                    </a>
                                </>}
                            </StyledSpan>
                        }
                    />
                </animated.div>
            })}
        </ActivityTimeline>
    </animated.div>
})

export default withSection(Experience)
