export const ES = {
    comingSoon: "Próximamente...",
    about: {
        title: "Sobre mí",
        paragraphs: [
            "Soy Desarrollador de Software con más de 10 años de experiencia en el desarrollo de aplicaciones con tecnologías Front End y Back End. Tengo una gran sed de aprendizaje, investigando constantemente nuevas tecnologías para el desarrollo de software. Me considero entusiasta del Scrum. Soy partidario del desarrollo de pruebas unitarias, de integración y del código limpio. Apasionado por la programación funcional.",
            "Actualmente estoy trabajando como desarrollador Front End para NinjaRMM, una empresa estadounidense, utilizando principalmente React, Less y Redux.",
            "Soy padre y esposo, amo pasar el tiempo con mi familia, jugando mucho con mi hijo, verlo crecer saludable y feliz es todo lo que puedo pedir para sentirme completo como persona.",
            "Nací y crecí en Esmeraldas, Ecuador. Debido a los estudios universitarios y a mejores oportunidades laborales tuve que mudarme a Quito, donde vivo más de 10 años.",
            "Me considero un geek de la tecnología, siempre pendiente de los nuevos lanzamientos tecnlógicos como celulares, computadores, accesorios, juegos de video, etc."
        ],
    },
    education: {
        title: "Educación",
        university: "Escuela Politécnica del Ejército",
        city: "Quito, Ecuador",
        degree: "Ingeniería en Ciencias de la Computación",
        titleCourses: "Cursos y Certificados",
        seeCertificate: "Ver Certificado",
        certifications: [
            {
                name: "Certificado EF SET",
                issuer: "EF International Language Centers",
                url: "https://www.efset.org/cert/KxnHja"
            },
            {
                name: "Curso Completo de React Native y Redux",
                issuer: "Udemy",
                url: "https://www.udemy.com/certificate/UC-917UR69C/"
            },
            {
                name: "Guía completa a Elasticsearch",
                issuer: "Udemy",
                url: "https://www.udemy.com/certificate/UC-JO5YZDBS/"
            },
            {
                name: "Scala: Aprender con Ejemplos",
                issuer: "Udemy",
                url: "http://ude.my/UC-9SLRWP6A"
            },
            {
                name: "Programación funcional en Java con Lambdas y Streams",
                issuer: "Udemy",
                url: "https://www.udemy.com/certificate/UC-HZF36Q2V/"
            }
        ]
    },
    experience: {
        title: "Experiencia",
        jobs: [
            {
                title: "Ninja RMM",
                time: "Julio 2019 - en curso",
                description: ["Desarrollo de nuevas funcionalidades en React y migración de Backbone a React para NinjaRMM, una plataforma para gestión y monitoreo remoto.",
                    "Líder técnico en proyectos para la implementación de nuevas funcionalidades.",
                    "Encargado de entrevistar a candidatos para la posición de Ingenieros de Front End."],
            },
            {
                title: "Sicpa",
                time: "Febrero 2019 - Julio 2019",
                description: ["Desarrollo de la platafoma SIMAR para la identificación, marcado, autenticación, seguimiento y trazabilidad fiscal de las bebidas alcohólicas, cervezas y cigarrillos de producción nacional."],
            },
            {
                title: "LatAm Autos",
                time: "Enero 2016 - Febrero 2019",
                description: ["Desarrollo de la plataforma web patiotuerca.com para Ecuador, México y varios países en América Latina.",
                    "Desarrollo de la aplicación web y microservicios de Motorfy, una aplicación para la generación y administración de créditos y garantías vehiculares."],
            },
            {
                title: "Dreamcode.io",
                time: "Enero 2018 - Mayo 2018",
                description: ["Desarrollo de funcionalidades de UI para aplicaciones web de empresas americanas."],
            },
            {
                title: "Grupo Céntrico",
                time: "Enero 2015 - Enero 2016",
                description: ["Desarrollo de la plataforma web evaluar.com, para los procesos de selección de personal y evaluaciones internas."],
            },
            {
                title: "Superintendencia de Economía Popular y Solidaria",
                time: "Julio 2014 - Diciembre 2014",
                description: ["Desarrollo de aplicaciones web para la carga de registros de depósitos, créditos, inversiones y transferencias para las cooperativas de ahorro y crédito del Ecuador.",
                    "Desarrolllo del sistema de administración de documentos para la secretaría general de la institución."],
            },
            {
                title: "VimeWorks",
                time: "Junio 2011 - Julio 2014",
                description: ["Desarrollo del portal y reportes para el Ministerio de Finanzas del Ecuador.",
                    "Desarrollo de la aplicación web para la administación de operaciones, cobros y reportes de los aeropuertos Daniel Oduber Quirós y Juan Santamaría en Costa Rica.",
                    "Desarrollo de reportes para el Instituto Nacional de Evaluación Educativa INEVAL.",
                    "Desarrollo de la plataforma web para la administación del registro de ubicaciones de estudientes de primaria y secundaria para el Ministerio de Educación del Ecuador."],
            }
        ],
        independent: {
            head: "Independiente",
            projects: [
                {
                    title: "Tesla Capacitación Continua",
                    time: "Junio 2019 - en curso",
                    description: "Desarrollo del portal web para la empresa y desarrollo de simuladores de tests de preparación para el examen Ser Bachiller.",
                    url: "teslacapacitaciones.com.ec",
                    ref: "https://www.teslacapacitaciones.com.ec/"
                },
                {
                    title: "SIGETH",
                    time: "Noviembre 2014 - Enero 2015",
                    description: "Aplicación web para el control y regulación del transporte público (Gobierno de la Provincia de Esmeraldas).",
                },
                {
                    title: "Sistema de Gestión de Cuadro de Mando Integral",
                    time: "Junio 2013 - Agosto 2013",
                    description: "Aplicación web de Cuadro de Mando Integral para gestión de TICS.",
                },
            ],
            title: "Tesla Capacitación Continua",
            time: "Junio 2019 - en curso",
            description: "Desarrollo del portal web para la empresa y desarrollo de simuladores de tests de preparación para el examen Ser Bachiller.",
        }
    },
    stack: {
        languages: "Lenguajes",
        libraries: "Librerías/Frameworks",
        databases: "Bases de datos",
        other: "Otras herramientas",
    }
}
