import store from "../store"

export const lightGray = "#d7d9e2"
export const lightGray2 = "#c2c4cd"
export const gray = "#5d5d5d"
export const darkGray = "#373737"
export const deepDarkGray = "#242424"
export const black = "#000000"
export const white = "#FFFFFF"

export const isDark = () => store.getState().ui.theme === "dark"

export const backgroundColor = () => isDark() ? darkGray : white
export const iconsColor = fromStack => fromStack ? gray : isDark() ? white : gray
export const textColor = () => isDark() ? white : gray
export const sideBarBackgroundColor = () => isDark() ? deepDarkGray : lightGray

export const themeColor = () => ({
    color: textColor()
})
