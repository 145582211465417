import React, {useState,useEffect,memo} from 'react'
import {
    FaBriefcase,
    FaGraduationCap,
    FaLinkedinIn,
    FaMoon,
    FaRegLightbulb,
    FaRegNewspaper,
    FaRegSun,
    FaUser,
} from "react-icons/fa"
import {iconsColor, lightGray2, textColor} from "../utils/colors"
import logo from "../img/logo.png"
import styled from "@emotion/styled"
import {AiOutlineMail} from "react-icons/ai"
import {SET_LANGUAGE, SET_THEME} from "../actions/actions"
import {connect} from "react-redux"
import {getString} from "../utils/language"

const StyledMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({isDesktopOrLaptop}) => isDesktopOrLaptop ? "240px" : "177px"};
`

const StyledImgMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    align-items: center;
`

const StyledOption = styled.div`
    display: flex;
    flex-direction: row;
    border-top: 1px solid ${lightGray2};
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    cursor: pointer;
    background-color: ${({selected}) => selected ? lightGray2 : "auto"};
    color: ${({color}) => color};
    &:hover {
        color: white;
        transition: 0.3s;
    }
`

const StyledButtons = styled.div`
    bottom: 1%;
    position: fixed;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
`

const StyledSpan = styled.span`
    user-select: none;
`

const IconStyle = {marginLeft: "15px", marginRight: "15px"}

const SideBarContent = memo(function ({theme, section, language, onChangeLanguage, onChangeSection, onChangeTheme, isDesktopOrLaptop}) {
    const [selectedItem, setSelectedItem] = useState("about")

    useEffect(() => {
        setSelectedItem(section)
    }, [section])

    const handleOnSelect = (e, selectedItem) => {
        e.preventDefault()
        onChangeSection(selectedItem)
        setSelectedItem({selectedItem})
    }

    const getIconProps = () => ({
        color: iconsColor(),
        size: "1.5em",
        style: {paddingRight: "20px", cursor: "pointer"},
        onClick: () => onChangeTheme(theme)
    })

    const textColorStyle = () => ({
        color: textColor(),
        userSelect: "none",
    })

    return (
        <>
            <StyledMenuContainer {...{isDesktopOrLaptop}}>
                <StyledImgMenuContainer>
                    <img src={logo} style={{userSelect: "none"}} alt="david" width="100"/>
                    {isDesktopOrLaptop && <>
                        <b style={{marginTop: "15px", ...textColorStyle()}}>David Jara</b>
                        <b style={{marginTop: "5px", ...textColorStyle()}}>Software Engineer</b>
                    </>}
                </StyledImgMenuContainer>
                <div style={{marginTop: "15px", marginBottom: "15px", justifyContent: "center", display: "flex"}}>
                    <a
                        href="mailto:david.andres.jara@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{marginRight: "10px"}}>
                        <AiOutlineMail size="1.5em" color={iconsColor()}/>
                    </a>
                    <a
                        href="https://www.linkedin.com/in/davidandresjara/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{}}>
                        <FaLinkedinIn size="1.5em" color={iconsColor()}/>
                    </a>
                </div>
                <StyledOption
                    color={textColor()}
                    onClick={e => handleOnSelect(e, "about")}
                    selected={"about" === selectedItem}>
                    <FaUser style={IconStyle} color={iconsColor()}/>
                    <StyledSpan>{getString("about.title")}</StyledSpan>
                </StyledOption>
                <StyledOption
                    color={textColor()}
                    onClick={e => handleOnSelect(e, "education")}
                    selected={"education" === selectedItem}>
                    <FaGraduationCap style={IconStyle} color={iconsColor()}/>
                    <StyledSpan>{getString("education.title")}</StyledSpan>
                </StyledOption>
                <StyledOption
                    color={textColor()}
                    onClick={e => handleOnSelect(e, "experience")}
                    selected={"experience" === selectedItem}>
                    <FaBriefcase style={IconStyle} color={iconsColor()}/>
                    <StyledSpan>{getString("experience.title")}</StyledSpan>
                </StyledOption>
                <StyledOption
                    color={textColor()}
                    onClick={e => handleOnSelect(e, "stack")}
                    selected={"stack" === selectedItem}>
                    <FaRegLightbulb style={IconStyle} color={iconsColor()}/>
                    <StyledSpan>Tech Stack</StyledSpan>
                </StyledOption>
                <StyledOption
                    color={textColor()}
                    onClick={e => handleOnSelect(e, "blog")}
                    selected={"blog" === selectedItem}>
                    <FaRegNewspaper style={IconStyle} color={iconsColor()}/>
                    <StyledSpan>Blog</StyledSpan>
                </StyledOption>
            </StyledMenuContainer>
            <div style={{display: "flex", justifyContent: "center"}}>
                <StyledButtons>
                    {theme === "dark" ? <FaMoon {...getIconProps()}/> : <FaRegSun {...getIconProps()}/>}
                    <div>
                        <b
                            style={{color: iconsColor(), fontSize: "16px", cursor: "pointer", userSelect: "none"}}
                            onClick={() => onChangeLanguage(language)}>
                            {language}
                        </b>
                    </div>
                </StyledButtons>
            </div>
        </>
    )
    
})

const mapStateToProps = state => {
    const {theme, language} = state.ui
    return {theme, language}
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeTheme: theme => dispatch(SET_THEME.action(theme === "dark" ? "light" : "dark")),
        onChangeLanguage: language => dispatch(SET_LANGUAGE.action(language === "ES" ? "EN" : "ES")),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContent)
