export const EN = {
    comingSoon: "Coming soon...",
    about: {
        title: "About",
        paragraphs: [
            "I'm a Software Developer with +10 years of experience in front end and back end technologies. I have a great thirst for learning, constantly researching new technologies for software development. Scrum enthusiast. Unit tests, integration tests and clean code supporter. Passionate about the paradigm of functional programming.",
            "Currently I'm working remotely as a Front End developer for NinjaRMM, a US based company, working mainly with React, Less and Redux technologies.",
            "Father and husband, I love to spend time with my family, playing a lot of games with my son, watching him healthy and happily grow are all that I can ask for to be happy.",
            "I was born and raised in Esmeraldas, Ecuador. Due to college studies and better job opportunities I had to move to Quito, where I have been living for more than 10 years.",
            "I consider myself a tech geek, always pending on the new tech releases; smartphones, computers, gadgets, video games, etc."
        ],
    },
    education: {
        title: "Education",
        university: "Escuela Politécnica del Ejército",
        city: "Quito, Ecuador",
        degree: "Engineer's Degree, Computer Science",
        titleCourses: "Courses & Certifications",
        seeCertificate: "See Certificate",
        certifications: [
            {
                name: "EF SET Certificate",
                issuer: "EF International Language Centers",
                url: "https://www.efset.org/cert/KxnHja"
            },
            {
                name: "The Complete React Native and Redux Course",
                issuer: "Udemy",
                url: "https://www.udemy.com/certificate/UC-917UR69C/"
            },
            {
                name: "Complete Guide to Elasticsearch",
                issuer: "Udemy",
                url: "https://www.udemy.com/certificate/UC-JO5YZDBS/"
            },
            {
                name: "Scala: Learn by Example",
                issuer: "Udemy",
                url: "http://ude.my/UC-9SLRWP6A"
            },
            {
                name: "Java Functional Programming with Lambdas and Streams",
                issuer: "Udemy",
                url: "https://www.udemy.com/certificate/UC-HZF36Q2V/"
            }
        ]
    },
    experience: {
        title: "Experience",
        jobs: [
            {
                title: "Ninja RMM",
                time: "July 2019 - Ongoing",
                description: ["UI features development and backbone migration for NinjaRMM, a remote monitoring and management platform.",
                    "Technical Leading several projects for new UI features implementation.",
                    "Responsible for Front End positions technical interviews."]
            },
            {
                title: "Sicpa",
                time: "February 2019 - July 2019",
                description: ["SIMAR platform development for the identification, marking, authentication, tracking and fiscal traceability of alcoholic beverages, beers and cigarettes from national production."],
            },
            {
                title: "LatAm Autos",
                time: "January 2016 - February 2019",
                description: ["Web platform development of vehicle marketing patiotuerca.com for Ecuador, México and several countries in Latin America",
                    "Motorfy web application and microservices development for the generation and administration of credit applications and vehicle guarantees"],
            },
            {
                title: "Dreamcode.io",
                time: "January 2018 - May 2018",
                description: ["UI features development for US companies' web applications."],
            },
            {
                title: "Grupo Céntrico",
                time: "January 2015 - January 2016",
                description: ["Web application evaluar.com development for personnel selection processes and internal evaluations."],
            },
            {
                title: "Superintendencia de Economía Popular y Solidaria",
                time: "July 2014 - December 2014",
                description: ["Web applications development for the loading of records of stockpiles, deposits, credits, investments and transfers for the Savings and Credit Cooperatives of Ecuador.",
                    "Document management system development for the institution’s general secretariat."],
            },
            {
                title: "VimeWorks",
                time: "June 2011 - July 2014",
                description: ["Portal and reports development for the Finance Ministry of Ecuador.",
                    "Web application development for the management of operations, billing and reports of Daniel Oduber Quirós and Juan Santamaría airports in Costa Rica.",
                    "Reports development for the National Institute of Educational Evaluation.",
                    "Web application development for management of registration and placement of elementary and secondary students of Ecuador for the Ministry of Education."],
            }
        ],
        independent: {
            head: "Independent",
            projects: [
                {
                    title: "Tesla Capacitación Continua",
                    time: "June 2019 - Ongoing",
                    description: "Company web page and tests simulator development for college students.",
                    url: "teslacapacitaciones.com.ec",
                    ref: "https://www.teslacapacitaciones.com.ec/"
                },
                {
                    title: "SIGETH",
                    time: "November 2014 - January 2015",
                    description: "Web application for control and regulation for public transportation (Esmeraldas Province Government).",
                },
                {
                    title: "Balanced Scorecard for IT",
                    time: "June 2013 - August 2013",
                    description: "Balance Scorecard Web application for IT management.",
                },
            ],
            title: "Tesla Capacitación Continua",
            time: "June 2019 - Ongoing",
            description: "Company web page and tests simulator development for college students.",
        }
    },
    stack: {
        languages: "Languages",
        libraries: "Libraries/Frameworks",
        databases: "Databases",
        other: "I'm also experienced with",
    }
}
