const alignment = {
    display: "flex",
    flexDirection: "column",
}

export const animatedBasic = {
    opacity: 1,
    config: {duration: 750},
    from: {opacity: 0},
}

export const animatedSection = {
    ...alignment,
    ...animatedBasic,
}
